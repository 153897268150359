import classNames from 'classnames';
import moment from 'moment';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { ReactComponent as StationIco } from '../../assets/charging-station-with-dashes.svg';
import { ReactComponent as PlugIco } from '../../assets/plug-blue.svg';
import { DotBadge } from '../../components-v2/badge/badge';
import {
  CHARGEPOINT_DOMAIN_STATUS_COLORS,
  STATION_OR_CHARGEPOINT_DOMAIN_STATUS_COLORS,
  stationOrChargepointStatusTranslation,
} from '../../components-v2/business-components/domain-mappings';
import { SmartSuggest } from '../../components-v2/business-components/suggestions/suggestions';
import { ButtonSegment, ButtonSegments, ButtonToggle, ButtonToggles } from '../../components-v2/button';
import DataTableV2, { sortChecker, sortHandler } from '../../components-v2/data-table/data-table';
import { DateRangeChooser } from '../../components-v2/date-range';
import FilterToggle from '../../components-v2/filter-toggle/filter-toggle';
import Ico from '../../components-v2/ico';
import { Page, PageContent, PageHeader, PageTitle } from '../../components-v2/page/page';
import { SelectedTag, SelectedTagArea, TagSuggestion, useTagSuggestion } from '../../components-v2/tag-suggestion/tag-suggestion';
import Box, { Divider, StackedSkeleton, TableRowSkeleton } from '../../components-v2/utils';
import { ExternalHardwareIcon, PublicStationIcon } from '../../components/evse-linfo-icons';
import { FormCard, FormCardBody } from '../../components/form-card';
import IconExplanationSection from '../../components/icon-explanation-section';
import NodeHierarchyLabel from '../../components/org-hierarchy/node-hierarchy-label';
import OrgHierarchyPicker from '../../components/org-hierarchy/org-hierarchy-picker';
import { ApiBasedContent } from '../../components/page-layout';
import Paging from '../../components/paging';
import Pill from '../../components/pill';
import colorStyles from '../../components/style-utils';
import { useApi } from '../../hooks/useApi';
import { useBetterNavigate } from '../../hooks/useBetterNavigate';
import { useMakePagingFilterParams, usePageParams } from '../../hooks/usePageParams';
import api from '../../services/api';
import { ChargeBoxOrConnectorStatus, InfrastructureLevel, MergedChargePointType, StatusLogSortableColumnFrontEnd } from '../../services/api-client/csp-api';
import { Unarray } from '../../ts-type-utils';
import { useAuth } from '../../utils/AuthProvider';
import { formatter } from '../../utils/localized-types';
import { createClientSearchProps } from '../../utils/node-picker-client-search-props';
import { dateRangeValidator, hierarchyValidator, pagingValidator, validateString } from '../../utils/queryParamValidators';
import useBetterTranslate from '../../utils/translation-utils';
import styles from './status-log-page.module.scss';
import Pagingation from '../../components-v2/pagination/pagination';

const IMPLICIT_FILTERS = ['skip', 'limit', 'clientCode', 'nodes'];

export interface StatusLogEntityFilterDto {
  title?: string;
  type: string;
  id: string;
}

export default function StatusLogPage() {
  const { _t } = useBetterTranslate('status-log-page');

  const { user } = useAuth();

  const navigate = useBetterNavigate<FilterParameters>();

  const MAX_DATE = useMemo(() => moment().add(1, 'day'), []);
  const MIN_DATE = useMemo(() => moment().subtract(2, 'year'), []);
  const DATE_FORMAT = 'YYYY-MM-DD';
  const SORT_BY_VALUES = ['entityId', 'manufacturer', 'model', 'statusStart'];

  type FilterParameters = {
    skip?: number | null;
    limit?: number | null;
    sortBy?: string;
    sortDesc?: string;
    clientCode?: string;
    nodes?: string[];
    search?: string;
    typeOfCurrent?: MergedChargePointType[];
    statuses?: ChargeBoxOrConnectorStatus[];
    entityFilter?: StatusLogEntityFilterDto[];
    level?: InfrastructureLevel[];
    from?: string;
    to?: string;
    public?: string;
  };

  const sortingValidator = { sortBy: validateString(SORT_BY_VALUES) };
  const validators = { ...dateRangeValidator(MIN_DATE, MAX_DATE), ...pagingValidator, ...hierarchyValidator, ...sortingValidator };

  const [filterParams, _setInnerFilterParams] = usePageParams<FilterParameters>(
    {
      from: moment().subtract(30, 'day').format(DATE_FORMAT),
      to: moment().format(DATE_FORMAT),
      statuses: [],
    },
    validators
  );

  const setFilterParams = useMakePagingFilterParams(_setInnerFilterParams);

  const [entityFilterOptions, setEntityFilterOptions] = useState<StatusLogEntityFilterDto[]>([]);
  const [hasExternalHardware, setHasExternalHardware] = useState(false);
  const [hasPublicStations, setHasPublicStations] = useState(false);

  const [logsResp, logsFetching, logsErr] = useApi(
    {
      call: async (
        clientCode?: string,
        hierarchyNodeCodes?: string[],
        skip?: number | null,
        limit?: number | null,
        search?: string,
        infrastructureLevel?: InfrastructureLevel[],
        statuses?: ChargeBoxOrConnectorStatus[],
        typeOfCurrent?: MergedChargePointType[],
        entityFilter?: StatusLogEntityFilterDto[],
        from?: string,
        to?: string,
        isPublic?: string,
        sortBy?: string,
        sortDesc?: string
      ) => {
        if (!clientCode) return undefined;

        const result = await api.statusLog.getStatusLogList(
          {
            limit: Number(limit || 20),
            skip: Number(skip || 0),
            clientCode,
            hierarchyNodeCodes: hierarchyNodeCodes || [clientCode],
            search,
            infrastructureLevel,
            statuses,
            typeOfCurrent,
            entityFilter,
            from,
            to,
            isPublic: isPublic === '1' ? true : undefined,
            sortBy: sortBy as StatusLogSortableColumnFrontEnd,
            sortDesc: sortDesc === '1',
          },
          {}
        );
        setEntityFilterOptions(result?.data?.entityFilter || []);
        setHasExternalHardware(!!result?.data?.entries.find((s) => s.isExternalHardware === true));
        setHasPublicStations(!!result?.data?.entries.find((s) => s.isPublic === true));
        return result;
      },
      map: (data) => {
        return data;
      },
    },
    filterParams.clientCode,
    filterParams.nodes,
    filterParams.skip,
    filterParams.limit,
    filterParams.search,
    filterParams.level,
    filterParams.statuses,
    filterParams.typeOfCurrent,
    filterParams.entityFilter,
    filterParams.from,
    filterParams.to,
    filterParams.public,
    filterParams.sortBy,
    filterParams.sortDesc
  );

  const hierarchy = [...(logsResp?.clientContext.accessableNodes || []), ...(logsResp?.clientContext?.forbiddenParents || [])];
  const getNodeCode = (nodePath: string) => {
    const path = !nodePath.endsWith('/') ? nodePath : nodePath.substring(0, nodePath.length - 1);
    return path.substring(path.lastIndexOf('/') + 1);
  };

  const stationStatusOptions = {
    groupTitle: _t('Ladestationen'),
    values: [
      { id: ChargeBoxOrConnectorStatus.ChargeBoxOnline, title: _t('Online'), pillColor: colorStyles.components.stationStatus.bg.online, dataCy: 'cs_online' },
      { id: ChargeBoxOrConnectorStatus.ChargeBoxOffline, title: _t('Offline'), pillColor: colorStyles.components.stationStatus.bg.offline, dataCy: 'cs_offline' },
      { id: ChargeBoxOrConnectorStatus.ChargeBoxFailure, title: _t('Fehlerhaft'), pillColor: colorStyles.components.stationStatus.bg.failure, dataCy: 'cs_defective' },
      {
        id: ChargeBoxOrConnectorStatus.ChargeBoxNotInOperation,
        title: _t('Nicht in betrieb'),
        pillColor: colorStyles.components.stationStatus.bg.notInOperation,
        dataCy: 'cs_notInOp',
      },
    ],
  };

  const connectorStatusOptions = {
    groupTitle: _t('Ladepunkte'),
    values: [
      { id: ChargeBoxOrConnectorStatus.ConnectorAvailable, title: _t('Verfügbar'), pillColor: colorStyles.components.connectorsStatus.bg.available, dataCy: 'cp_available' },
      { id: ChargeBoxOrConnectorStatus.ConnectorCharging, title: _t('Laden'), pillColor: colorStyles.components.connectorsStatus.bg.charging, dataCy: 'cp_charging' },
      { id: ChargeBoxOrConnectorStatus.ConnectorOccupied, title: _t('Besetzt'), pillColor: colorStyles.components.connectorsStatus.bg.occupied, dataCy: 'cp_occupied' },
      { id: ChargeBoxOrConnectorStatus.ConnectorFailure, title: _t('Fehlerhaft'), pillColor: colorStyles.components.connectorsStatus.bg.failure, dataCy: 'cp_defective' },
      {
        id: ChargeBoxOrConnectorStatus.ConnectorNotAvailableReserved,
        title: _t('Nicht verfügbar'),
        subtitle: `(${_t('Reserviert')})`,
        pillColor: colorStyles.components.connectorsStatus.bg.unavailable,
        dataCy: 'cp_reserved',
      },
      {
        id: ChargeBoxOrConnectorStatus.ConnectorNotAvailableLocked,
        title: _t('Nicht verfügbar'),
        subtitle: `(${_t('Gesperrt')})`,
        pillColor: colorStyles.components.connectorsStatus.bg.unavailable,
        dataCy: 'cp_unavailable',
      },
      {
        id: ChargeBoxOrConnectorStatus.ConnectorNotAvailableNone,
        title: _t('Nicht verfügbar'),
        subtitle: `(${_t('Sonstige')})`,
        pillColor: colorStyles.components.connectorsStatus.bg.unavailable,
        dataCy: 'cp_other',
      },
      { id: ChargeBoxOrConnectorStatus.ConnectorUnknown, title: _t('Unbekannt'), pillColor: colorStyles.components.connectorsStatus.bg.unknown, dataCy: 'cp_unknown' },
    ],
  };

  const StatusIndicator = (props: PropsWithChildren<{ entryStatus: ChargeBoxOrConnectorStatus }>) => {
    const mapStatusToPillColor = (combinedStatus: ChargeBoxOrConnectorStatus) => {
      const mappedPillColor = stationStatusOptions.values.map((value) => value.id).includes(combinedStatus)
        ? stationStatusOptions.values.find((value) => value.id === combinedStatus)?.pillColor
        : connectorStatusOptions.values.find((value) => value.id === combinedStatus)?.pillColor;
      return mappedPillColor || '';
    };
    let statusTitle = '';
    let statusSubtitle = '';

    if (stationStatusOptions.values.map((value) => value.id).includes(props.entryStatus)) {
      statusTitle = stationStatusOptions.values.find((value) => value.id === props.entryStatus)?.title || '';
    } else {
      statusTitle = connectorStatusOptions.values.find((value) => value.id === props.entryStatus)?.title || '';
    }
    if (props.entryStatus === ChargeBoxOrConnectorStatus.ConnectorNotAvailableReserved || props.entryStatus === ChargeBoxOrConnectorStatus.ConnectorNotAvailableLocked) {
      statusSubtitle = connectorStatusOptions.values.find((value) => value.id === props.entryStatus)?.subtitle || '';
    }

    return (
      <>
        <div>
          {' '}
          <Pill className={classNames(styles.statusPill, mapStatusToPillColor(props.entryStatus))}></Pill>
        </div>
        <div>{statusTitle}</div>
        <div className={styles.subLabel}>{statusSubtitle}</div>
      </>
    );
  };

  const searchEntityTags = async (clientCode: string | undefined, nodes: string[] | undefined, txt: string) => {
    if (!clientCode) return [];
    const hierarchyNodeCodes = nodes ? nodes : [clientCode];
    const entityFilterRes = await api.statusLog.searchFilters({ q: txt, clientCode, hierarchyNodeCodes });
    return entityFilterRes.data;
  };

  type ArrayToggles = 'level' | 'typeOfCurrent' | 'statuses';
  const checkFilterToggle = <K extends ArrayToggles>(key: K, value: NonNullable<Unarray<FilterParameters[K]>>): boolean => {
    return filterParams[key] && (filterParams[key] as any).includes(value);
  };

  const toggleFilter = <K extends ArrayToggles>(key: K, value: NonNullable<Unarray<FilterParameters[K]>>): void => {
    const currentFilters = filterParams[key] || [];
    const updatedFilters = (currentFilters as any).includes(value) ? [...(currentFilters as any).filter((v: any) => v !== value)] : [...(currentFilters as any), value];
    setFilterParams({ [key]: updatedFilters });
  };

  const [filterSectionExpanded, setFilterSectionExpanded] = useState(true);
  const clearFilters = () => {
    const changeParams: FilterParameters = {};
    for (const [k] of Object.entries(filterParams)) {
      if (IMPLICIT_FILTERS.includes(k)) continue;
      (changeParams as any)[k as any] = undefined;
    }

    setFilterParams(changeParams);
  };

  const suggestion = useTagSuggestion({
    fetchOptions: async (val) => searchEntityTags(logsResp?.clientContext?.code, filterParams.nodes, val),
    createOption: (val) => <SmartSuggest label={val.title || ''} type={val.type} />,
    filterDuplicates: true,
    provideIdentifier: (val) => val.id,
    createSelectedTag: (val, onRemove) => <SelectedTag onRemove={onRemove}>{val.title}</SelectedTag>,
  });
  const entityFilters = suggestion.selectedItems;
  useEffect(() => {
    setFilterParams({ entityFilter: entityFilters });
  }, [entityFilters, setFilterParams]);

  // const checkFilterLevel = (lvl: InfrastructureLevel) => {
  //   return filterParams.level && filterParams.level.includes(lvl)
  // }

  // const toggleFilterLvl = (lvl: InfrastructureLevel) => {
  //   const currentFilters = filterParams.level || [];
  //   const currentFiltersWithoutChargebox = [...currentFilters].filter((value) => value !== lvl);
  //   currentFilters.includes(lvl)
  //     ? setFilterParams({ level: currentFiltersWithoutChargebox })
  //     : setFilterParams({ level: [...currentFilters, lvl] });
  // }

  return (
    <Page>
      <PageHeader>
        <PageTitle>{_t('Status Log')}</PageTitle>
      </PageHeader>
      <PageContent>
        <OrgHierarchyPicker
          selectMode='node'
          clientContext={logsResp?.clientContext}
          onNodeSelected={(clientCode, selectedCodes) => {
            if (clientCode !== logsResp?.clientContext?.code) {
              navigate(`/status-log/${clientCode}`, { nodes: selectedCodes });
              window.location.reload();
            } else {
              setFilterParams({ nodes: selectedCodes });
            }
          }}
          {...createClientSearchProps(logsResp?.clientContext)}
          selectedNodes={filterParams.nodes}
        />

        {(hasExternalHardware || hasPublicStations) && (
          <FormCard phoneSize='full'>
            <FormCardBody>
              <IconExplanationSection showExternalHardware={hasExternalHardware} showPublicStations={hasPublicStations} />
            </FormCardBody>
          </FormCard>
        )}

        <Box kind={'hflex'} justify='flex-end'>
          <ButtonSegments>
            <ButtonSegment
              dataCy='charging_station_pill'
              toggled={checkFilterToggle('level', InfrastructureLevel.Station)}
              onClick={() => toggleFilter('level', InfrastructureLevel.Station)}
            >
              <Box kind={'hflex'} align='center' gap='xxs' pad={'100'}>
                <Ico size='16px' file={<StationIco />} fill={checkFilterToggle('level', InfrastructureLevel.Station) ? 'primary-white' : 'primary-500'} />
                {_t('Station')}
              </Box>
            </ButtonSegment>
            <ButtonSegment
              dataCy='charging_point_pill'
              toggled={checkFilterToggle('level', InfrastructureLevel.Connector)}
              onClick={() => toggleFilter('level', InfrastructureLevel.Connector)}
            >
              <Box kind={'hflex'} align='center' gap='xxs' pad={'100'}>
                <Ico size='16px' file={<PlugIco />} fill={checkFilterToggle('level', InfrastructureLevel.Connector) ? 'primary-white' : 'primary-500'} />
                {_t('Ladepunkt')}
              </Box>
            </ButtonSegment>
          </ButtonSegments>
        </Box>

        <Box className={styles.mainContainer} kind={'vflex'} gap='m' pad={'500'}>
          <Box kind={'hflex'} gap='m' align='center'>
            <TagSuggestion {...suggestion.suggestorProps} testid='search' />
            <ButtonSegments size='s'>
              <ButtonSegment
                onClick={() => toggleFilter('typeOfCurrent', MergedChargePointType.AC)}
                toggled={checkFilterToggle('typeOfCurrent', MergedChargePointType.AC)}
                dataCy='ac'
              >
                {_t('AC')}
              </ButtonSegment>
              <ButtonSegment
                onClick={() => toggleFilter('typeOfCurrent', MergedChargePointType.DC)}
                toggled={checkFilterToggle('typeOfCurrent', MergedChargePointType.DC)}
                dataCy='dc'
              >
                {_t('DC')}
              </ButtonSegment>
              <ButtonSegment
                onClick={() => toggleFilter('typeOfCurrent', MergedChargePointType.ACDC)}
                toggled={checkFilterToggle('typeOfCurrent', MergedChargePointType.ACDC)}
                dataCy='ac_dc'
              >
                {_t('AC/DC')}
              </ButtonSegment>
            </ButtonSegments>
            <Divider />

            <ButtonSegments size='s'>
              <ButtonSegment
                onClick={() => setFilterParams({ public: filterParams.public === '1' ? undefined : '1' })}
                toggled={filterParams.public === '1'}
                dataCy='public_service'
              >
                {_t('Public Service')}
              </ButtonSegment>
            </ButtonSegments>

            <Box kind={'hflex'} justify='flex-end' flexGrow='1' gap='m'>
              <DateRangeChooser
                minDate={MIN_DATE.toDate()}
                maxDate={MAX_DATE.toDate()}
                range={filterParams.from && filterParams.to ? { startDate: filterParams.from, endDate: filterParams.to } : undefined}
                onChange={(range) => {
                  if (range) setFilterParams({ from: range.startDate, to: range.endDate });
                }}
              />
              <Divider kind='v' />
              <FilterToggle onClear={clearFilters} showClear={filterSectionExpanded} toggleExpand={() => setFilterSectionExpanded((current) => !current)} />
            </Box>
          </Box>
          {filterSectionExpanded && (
            <>
              <Box kind={'hflex'} justify='flex-end' gap='m' wrap testId='quick_filters'>
                {/* <Box kind={'hflex'} > */}
                <ButtonToggles size='xs'>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ChargeBoxOnline)}
                    dataCy='station_online'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ChargeBoxOnline)}
                    circle={{ color: 'green-kelly-green' }}
                  >
                    {_t('Online')}
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ChargeBoxOffline)}
                    dataCy='station_offline'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ChargeBoxOffline)}
                    circle={{ color: 'dataviz-brown-naples-yellow' }}
                  >
                    {_t('Offline')}
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ChargeBoxFailure)}
                    dataCy='station_defective'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ChargeBoxFailure)}
                    circle={{ color: 'functional-error-full' }}
                  >
                    {_t('Defective')}
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ChargeBoxNotInOperation)}
                    dataCy='station_not_in_operation'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ChargeBoxNotInOperation)}
                    circle={{ color: 'primary-neutral-090' }}
                  >
                    <Box ellipses>{_t('Not in operation')}</Box>
                  </ButtonToggle>
                </ButtonToggles>

                <ButtonToggles size='xs' wrap>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorAvailable)}
                    dataCy='connector_available'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorAvailable)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.available }}
                  >
                    {_t('Available')}
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorCharging)}
                    dataCy='connector_charging'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorCharging)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.charging }}
                  >
                    {_t('Charging')}
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorOccupied)}
                    dataCy='connector_occupied'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorOccupied)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.occupied }}
                  >
                    {_t('Occupied')}
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorFailure)}
                    dataCy='connector_defective'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorFailure)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.failure }}
                  >
                    {_t('Defective')}
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorNotAvailableReserved)}
                    dataCy='connector_unavailable_reserved'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorNotAvailableReserved)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.notAvailable }}
                  >
                    <Box ellipses>{_t('N/A Reserved')}</Box>
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorNotAvailableLocked)}
                    dataCy='connector_unavailable_locked'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorNotAvailableLocked)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.notAvailable }}
                  >
                    <Box ellipses>{_t('N/A Set unavailable')}</Box>
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorNotAvailableNone)}
                    dataCy='connector_unavailable_other'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorNotAvailableNone)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.notAvailable }}
                  >
                    <Box ellipses>{_t('N/A Other')}</Box>
                  </ButtonToggle>
                  <ButtonToggle
                    toggled={checkFilterToggle('statuses', ChargeBoxOrConnectorStatus.ConnectorUnknown)}
                    dataCy='connector_unknown'
                    onClick={() => toggleFilter('statuses', ChargeBoxOrConnectorStatus.ConnectorUnknown)}
                    circle={{ color: CHARGEPOINT_DOMAIN_STATUS_COLORS.unknown }}
                  >
                    {_t('Unknown')}
                  </ButtonToggle>
                </ButtonToggles>
                {/* </Box> */}
              </Box>
              <Box>
                <SelectedTagArea {...suggestion.selectedTagAreaProps} />
              </Box>
            </>
          )}

          <ApiBasedContent err={logsErr} fetching={logsFetching} resp={logsResp} placeholder={() => <StackedSkeleton skeleton={() => <TableRowSkeleton />} />}>
            {(logsResp) => {
              return (
                <Box kind={'vflex'} gap='m'>
                  <DataTableV2
                    records={logsResp.entries}
                    layout={[
                      {
                        width: '2fr',
                        header: {
                          onSort: sortHandler('entityId', setFilterParams),
                          sorting: sortChecker('entityId', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs'>
                              <Box>{_t('EVSEID')}</Box>
                              <Box>{_t('Standort')}</Box>
                            </Box>
                          ),
                        },
                        // subHeader: {
                        //   node: () => <></>
                        // },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} gap='xs' justify='center' h='100%'>
                                <Box fw='800'>
                                  {record.entityId}
                                  <ExternalHardwareIcon isExternalHardware={record.isExternalHardware} />
                                  <PublicStationIcon isPublic={record.isPublic} />
                                </Box>
                                <NodeHierarchyLabel
                                  className={styles.wrapLabel}
                                  allNodes={hierarchy}
                                  code={getNodeCode(record.clientHierarchy)}
                                  hideClientRoot={true}
                                  hideRegion={true}
                                  hideArea={true}
                                  characterForNoValue={'-'}
                                />
                              </Box>
                            );
                          },
                        },
                      },
                      {
                        width: '1fr',
                        header: {
                          // was not supported
                          // onSort: sortHandler('level', setFilterParams),
                          // sorting: sortChecker('level', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs'>
                              <Box>{_t('Level')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='center' h='100%'>
                                <Ico fill='primary-neutral-085' size='24px' file={record.isConnector ? <PlugIco /> : <StationIco />} />
                              </Box>
                            );
                          },
                        },
                      },

                      {
                        width: '2fr',
                        header: {
                          onSort: sortHandler('manufacturer', setFilterParams),
                          sorting: sortChecker('manufacturer', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs'>
                              <Box>{_t('Hersteller')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='center' h='100%'>
                                {record.manufacturer || '-'}
                              </Box>
                            );
                          },
                        },
                      },

                      {
                        width: '2fr',
                        header: {
                          onSort: sortHandler('model', setFilterParams),
                          sorting: sortChecker('model', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs'>
                              <Box>{_t('Modell')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='center' h='100%'>
                                {record.model || '-'}
                              </Box>
                            );
                          },
                        },
                      },

                      {
                        width: '1fr',
                        header: {
                          // was not supported
                          // onSort: sortHandler('xx', setFilterParams),
                          // sorting: sortChecker('xx', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs'>
                              <Box>{_t('Stromart')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='center' h='100%'>
                                {record.typeOfCurrent || '-'}
                              </Box>
                            );
                          },
                        },
                      },

                      {
                        width: '1fr',
                        header: {
                          onSort: sortHandler('statusStart', setFilterParams),
                          sorting: sortChecker('statusStart', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs'>
                              <Box>{_t('Start')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='center' h='100%'>
                                <Box fw='800'>
                                  {formatter.formatDateFromIsoString(record.statusStartAt, user?.preferences.languageCode, { day: 'numeric', month: 'numeric', year: '2-digit' })}
                                </Box>
                                <Box>{formatter.formatDateTimeFromIsoString(record.statusStartAt, user?.preferences.languageCode, { timeStyle: 'short' })}</Box>
                              </Box>
                            );
                          },
                        },
                      },

                      {
                        width: '1fr',
                        header: {
                          // onSort: sortHandler('xxx', setFilterParams),
                          // sorting: sortChecker('xxx', filterParams),
                          node: () => (
                            <Box fw='800' kind={'vflex'} gap='xxs'>
                              <Box>{_t('Status')}</Box>
                            </Box>
                          ),
                        },
                        cell: {
                          node: (record) => {
                            return (
                              <Box kind={'vflex'} justify='center' h='100%'>
                                <DotBadge circleColor={STATION_OR_CHARGEPOINT_DOMAIN_STATUS_COLORS[record.status]}>
                                  {stationOrChargepointStatusTranslation(record.status as ChargeBoxOrConnectorStatus)}
                                </DotBadge>
                              </Box>
                            );
                          },
                        },
                      },
                    ]}
                  />

                  <Pagingation
                    skip={logsResp.skip}
                    limit={logsResp.limit}
                    total={logsResp.total}
                    onChange={(arg) => {
                      setFilterParams({ skip: arg.skip <= 0 ? null : arg.skip, limit: arg.limit });
                    }}
                  />
                </Box>
              );
            }}
          </ApiBasedContent>
        </Box>
      </PageContent>
    </Page>
  );
}
